import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import {
  DataRoomOverviewSettings,
  IndUserProfileSettings,
} from '~/core/domain/types';
import { QueryKeys } from '~/core/hooks/const';
import { useSessionValidation } from '~/core/hooks/core/useSessionValidation';
import logger from '~/core/providers/logger';
import * as UserService from '~/core/services/UserService';

import UserContext from '.';

const STALE_TIME = 5 * 60 * 1000; // 5 minutes

const UserProviderQueryKeys = {
  GET_PROFILE_SETTINGS: ['user-profile-settings'],
  GET_FEATURE_FLAGS: ['user-feature-flags'],
};

interface UserProviderProps {
  children: React.ReactNode;
  onSessionInvalid: () => void;
  featureFlagRefreshIntervalInMilliseconds?: number;
}

export const UserProvider = ({
  children,
  onSessionInvalid,
  featureFlagRefreshIntervalInMilliseconds = 2 * 60 * 1000, // 2 minutes
}: UserProviderProps) => {
  const queryClient = useQueryClient();
  const [dataRoomOverviewSettings, setDataRoomOverviewSettings] =
    useState<DataRoomOverviewSettings>({});

  // This intentionally does not use the useQuery hook, as we're looking to
  // isolate what might be causing any feature flag lookup failure.
  const [userFeatureFlags, setUserFeatureFlags] = useState<any>({});

  // Validate Session
  useSessionValidation(onSessionInvalid);

  // Fetch User Profile
  const {
    data: userProfile = UserService.defaultProfile,
    isLoading: loadingUserProfile,
  } = useQuery({
    queryKey: QueryKeys.GET_PROFILE,
    queryFn: async () => {
      const profile = await UserService.getProfile();
      if (profile) {
        UserService.identifyUser(profile);
      }
      return profile;
    },
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  // Fetch User Profile Settings
  const { data: userProfileSettings = {} } = useQuery(
    {
      queryKey: UserProviderQueryKeys.GET_PROFILE_SETTINGS,
      queryFn: async () => await UserService.getProfileSettings(),

      staleTime: STALE_TIME,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
    queryClient,
  );

  const getFeatureFlagsAsync = async ({
    blockUntilCacheIsUpdated = false,
  }: {
    blockUntilCacheIsUpdated?: boolean;
  }) => {
    const selectedOrg = userProfile.organizations.find(
      (org) => org.is_selected,
    );
    if (!selectedOrg) {
      logger.logError(
        'Cannot find a selected organization for the user in question.  Returning.',
      );
      return;
    }
    const featureFlagResponse = await UserService.getFeatureFlags({
      userId: userProfile.uuid,
      organizationId: selectedOrg.organization.uuid,
      forceRefreshOfCache: blockUntilCacheIsUpdated,
    });
    if (featureFlagResponse) {
      console.log('Feature flag response', featureFlagResponse);
      setUserFeatureFlags(featureFlagResponse);
    }
  };

  useEffect(() => {
    // @j-weave: We don't need to block until the cache is updated for a user UUID switch, because
    // that is handled in advance at login.
    if (userProfile.uuid) {
      getFeatureFlagsAsync({ blockUntilCacheIsUpdated: false });
    }
  }, [userProfile.uuid]);

  useInterval(() => {
    // Refresh the FFs if the interval has elapsed
    getFeatureFlagsAsync({ blockUntilCacheIsUpdated: false });
  }, featureFlagRefreshIntervalInMilliseconds);

  // Mutation for Updating Profile Settings
  const updateUserProfileSettings = useMutation<
    IndUserProfileSettings,
    Error,
    any
  >({
    mutationFn: async (data) => await UserService.updateProfileSettings(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: UserProviderQueryKeys.GET_PROFILE_SETTINGS,
      });
    },
  });

  useEffect(() => {
    if (userProfile.uuid.length > 0) {
      queryClient.invalidateQueries({
        queryKey: UserProviderQueryKeys.GET_PROFILE_SETTINGS,
      });
    }
  }, [userProfile.uuid]);

  useEffect(() => {
    if (userProfileSettings.dataRoomOverviewSettings) {
      setDataRoomOverviewSettings(
        userProfileSettings?.dataRoomOverviewSettings || {},
      );
    }
  }, [userProfileSettings.dataRoomOverviewSettings]);

  return (
    <UserContext.Provider
      value={{
        dataRoomOverviewSettings,
        loadingUserProfile,
        userProfile,
        userProfileSettings,
        userFeatureFlags,
        updateUserProfileSettings: async (data) => {
          await updateUserProfileSettings.mutateAsync(data);
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
