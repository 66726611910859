import * as UserService from '~/core/services/UserService';
import { useSessionStore } from '~/core/store/sessions-store';

export const refreshSession = async () => {
  const authenticated = await UserService.checkAuthValidity();
  if (authenticated) {
    const userProfile = await UserService.getProfile();
    const session = await UserService.getCurrentUserSessionSession(userProfile);

    useSessionStore.getState().setSession(session);
    UserService.identifyUser(userProfile);
  } else {
    UserService.resetIdentification();
  }
};
