interface SortOptions {
  order?: 'asc' | 'desc';
  caseSensitivity?: 'sensitive' | 'insensitive';
}

const isNullOrUndefined = (obj: any) => {
  return obj === null || obj === undefined;
};
export class Sort {
  static by = <T>(
    array: T[],
    propFunc: (t: T) => any,
    sortOptions: SortOptions = {
      order: 'asc',
      caseSensitivity: 'insensitive',
    },
  ) => {
    if (!array || !propFunc || !array.length) {
      return array;
    }

    const order = sortOptions.order || 'asc';
    const caseSensitivity = sortOptions.caseSensitivity || 'insensitive';

    if (
      typeof propFunc(array[0]) === 'string' &&
      caseSensitivity === 'insensitive'
    ) {
      return [...array].sort((a, b) => {
        if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
          return 0;
        }
        if (isNullOrUndefined(a)) {
          return order === 'asc' ? -1 : 1;
        }
        if (isNullOrUndefined(b)) {
          return order === 'asc' ? 1 : -1;
        }
        const aResult = propFunc(a);
        const bResult = propFunc(b);
        if (isNullOrUndefined(aResult) && isNullOrUndefined(bResult)) {
          return 0;
        }
        if (isNullOrUndefined(aResult)) {
          return order === 'asc' ? -1 : 1;
        }
        if (isNullOrUndefined(bResult)) {
          return order === 'asc' ? 1 : -1;
        }
        if (aResult.toLocaleLowerCase() < bResult.toLocaleLowerCase()) {
          return order === 'asc' ? -1 : 1;
        }

        if (aResult.toLocaleLowerCase() > bResult.toLocaleLowerCase()) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return [...array].sort((a, b) => {
      if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
        return 0;
      }
      if (isNullOrUndefined(a)) {
        return order === 'asc' ? -1 : 1;
      }
      if (isNullOrUndefined(b)) {
        return order === 'asc' ? 1 : -1;
      }
      if (propFunc(a) < propFunc(b)) {
        return order === 'asc' ? -1 : 1;
      }

      if (propFunc(a) > propFunc(b)) {
        return order === 'asc' ? 1 : -1;
      }

      return 0;
    });
  };
}
