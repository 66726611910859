// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import listToTree from 'list-to-tree-lite';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import flatten from 'tree-flatten';

interface TreeUtilFlattenTreeOptions {
  childPropName: string;
}

interface TreeUtilCreateFromArrayOptions {
  childPropName: string;
  idPropName: string;
  parentPropname: string;
}

export class TreeUtils {
  static flattenTree({
    sourceTree,
    options = { childPropName: 'children' },
  }: {
    sourceTree: any;
    options?: TreeUtilFlattenTreeOptions;
  }): Array<any> {
    return flatten(sourceTree, options.childPropName);
  }

  static createFromArray({
    sourceArray,
    options,
  }: {
    sourceArray: any;
    options?: TreeUtilCreateFromArrayOptions;
  }): any {
    if (options) {
      const listToTreeOptionsObj = {
        idKey: options.idPropName || 'id',
        parentKey: options.parentPropname || 'parent',
        childrenKey: options.childPropName || 'children',
      };
      return listToTree(sourceArray, listToTreeOptionsObj);
    }
    return listToTree(sourceArray, options);
  }

  static nodeHasDescendantWithCondition<TNodeType>({
    node,
    descendantAccessor,
    matchingCondition,
  }: {
    node: TNodeType;
    descendantAccessor: (node: TNodeType) => TNodeType[];
    matchingCondition: (node: TNodeType) => boolean;
  }): boolean {
    const descendants = descendantAccessor(node);
    for (const descendant of descendants) {
      if (
        matchingCondition(descendant) ||
        this.nodeHasDescendantWithCondition({
          node: descendant,
          descendantAccessor,
          matchingCondition,
        })
      ) {
        return true;
      }
    }
    return false;
  }

  static findNodeById<TNodeType extends { id: string; children: TNodeType[] }>(
    tree: TNodeType,
    id: string,
  ): TNodeType | null {
    if (Array.isArray(tree)) {
      for (const node of tree) {
        const found = TreeUtils.findNodeById(node, id);
        if (found) return found;
      }
      return null;
    }

    if (tree.id === id) {
      return tree;
    }

    if (tree.children) {
      for (const child of tree.children) {
        const found = TreeUtils.findNodeById(child, id);
        if (found) return found;
      }
    }
    return null;
  }

  static replaceNode<TNodeType extends { id: string; children: TNodeType[] }>(
    tree: TNodeType,
    replacementNode: TNodeType,
  ): TNodeType | TNodeType[] {
    // Found matching node - return replacement
    if (tree.id === replacementNode.id) {
      return { ...replacementNode };
    }

    // No children - return unchanged node
    if (!tree.children.length) {
      return { ...tree };
    }

    // Process children recursively
    return {
      ...tree,
      children: tree.children.map((child) =>
        TreeUtils.replaceNode(child, replacementNode),
      ),
    };
  }
}
