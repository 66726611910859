import { createContext } from 'react';

import { IndProfile, IndUserProfileSettings } from '~/core/domain/types';
import * as UserService from '~/core/services/UserService';

import { DataRoomOverviewSettings } from '../../domain/types';

type UserContextType = {
  dataRoomOverviewSettings: DataRoomOverviewSettings;
  loadingUserProfile: boolean;
  userProfile: IndProfile;
  userProfileSettings: IndUserProfileSettings | null;
  updateUserProfileSettings: (
    settings: IndUserProfileSettings,
  ) => Promise<void>;
  userFeatureFlags: Record<string, boolean>;
};

const UserContext = createContext<UserContextType>({
  dataRoomOverviewSettings: {},
  loadingUserProfile: false,
  userProfile: UserService.defaultProfile,
  userProfileSettings: null,
  updateUserProfileSettings: async () => {},
  userFeatureFlags: {},
});

export default UserContext;
