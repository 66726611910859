import { PrivateRoutes } from './private-routes';

export const Routing = {
  DocumentEditor: ({
    indId,
    sectionNumber,
    lens = 'edit',
    subsectionNumber,
    blockId,
  }: {
    indId: string;
    sectionNumber: string;
    lens?: string;
    subsectionNumber?: string;
    blockId?: string;
  }) => {
    const baseUrl = PrivateRoutes.DOCUMENT_EDITOR.replace(':indId', indId)
      .replace(':sectionNumber', sectionNumber)
      .replace(':lens', lens);
    // return in order of specificity
    if (blockId) {
      return [baseUrl, '#b_', blockId.replaceAll('-', '_')].join('');
    }
    if (subsectionNumber) {
      return [baseUrl, '#ds_', subsectionNumber.replaceAll('.', '_')].join('');
    }
    return baseUrl;
  },

  AssetDocumentEditor: ({
    indId,
    documentNumber,
    lens = 'edit',
  }: {
    indId: string;
    documentNumber: string;
    lens?: string;
  }) => {
    const baseUrl = PrivateRoutes.ASSET_DOCUMENT_EDITOR.replace(':indId', indId)
      .replace(':sectionNumber', documentNumber)
      .replace(':lens', lens);
    return baseUrl;
  },
};

export const getDataRoomPath = (
  indId: string,
  sectionNumber: string | undefined,
  allowSingleDoc?: boolean,
) => {
  return `${PrivateRoutes.DATA_ROOM.replace(':indId', indId)}?returnUrl=${
    allowSingleDoc ?
      Routing.AssetDocumentEditor({
        indId,
        documentNumber: sectionNumber ?? '',
      })
    : `/ind-manager/${indId}?lastGenerateSectionNumber=${sectionNumber}`
  }`;
};

export const getDataRoomExtractedDataPath = (indId: string, fileId: string) => {
  // Unlike most urls in the app, this one is actually a pointer to a different resource entirely.
  const baseApiUrl = import.meta.env.VITE_IND_API_URL;
  return [baseApiUrl, 'ind', indId, 'files', fileId, 'content'].join('/');
};

export const getTemplatesPath = (
  indId: string,
  documentLevelSectionNumber: string,
) => {
  return `${PrivateRoutes.TEMPLATE.replace(':indId', indId)}?sectionNumber=${documentLevelSectionNumber}`;
};
