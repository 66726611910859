import { MenuProps } from 'antd';

import { Sort } from '~/core/lib/sortUtils';
import BodyText from '~/design-system/components/atoms/Text/Body';

export const constructIndDropdownItems = ({
  userInds,
  organizationInds,
  onClick,
}: {
  userInds: Array<IndApplication>;
  organizationInds: Array<IndApplication>;
  onClick: (indId: string) => void;
}) => {
  // The user INDs are sorted in descending edited order.  Take the first three items from the user INDs
  // to be the most recent,
  // and then sort the remainder of the INDs by name.
  const mostRecentUserInds = userInds.slice(0, 3);
  const organizationIndsSortedByName = organizationInds.sort((a, b) =>
    a.drugName.localeCompare(b.drugName),
  );

  const items: MenuProps['items'] = [];
  if (mostRecentUserInds.length > 0) {
    items.push({
      label: (
        <BodyText size="body3" weight="semibold" color="text-weave-primary">
          Most Recent INDs
        </BodyText>
      ),
      type: 'group',
    });
    mostRecentUserInds.forEach((indApp) => {
      items.push({
        label: indApp.drugName,
        key: indApp.id,
        onClick: () => {
          onClick(indApp.id);
        },
      });
    });
  }
  if (mostRecentUserInds.length > 0 && userInds.length > 0) {
    items.push({ type: 'divider', dashed: true, className: 'bg-green-3' });
  }
  if (userInds.length > 0) {
    items.push({
      label: (
        <BodyText size="body3" weight="semibold" color="text-weave-primary">
          Your INDs
        </BodyText>
      ),
      type: 'group',
    });
    Sort.by(userInds, (ind) => ind.drugName).forEach((indApp) => {
      items.push({
        label: indApp.drugName,
        key: indApp.id,
        onClick: () => {
          onClick(indApp.id);
        },
      });
    });
  }
  if (
    (mostRecentUserInds.length > 0 || userInds.length > 0) &&
    organizationInds.length > 0
  ) {
    items.push({
      type: 'divider',
      style: { borderWidth: 5, borderColor: 'weave-gray' },
    });
  }
  if (organizationIndsSortedByName.length > 0) {
    items.push({
      label: (
        <BodyText size="body3" weight="semibold" color="text-weave-gray">
          Other INDs
        </BodyText>
      ),
      type: 'group',
    });
    organizationIndsSortedByName.forEach((indApp) => {
      items.push({
        label: indApp.drugName,
        key: indApp.id,
        onClick: () => {
          onClick(indApp.id);
        },
      });
    });
  }
  return items;
};
