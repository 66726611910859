export const weaveColors = {
  'weave-black': '#000000e0',
  'weave-green': '#ADCD83',
  'weave-green-dark': '#A4C17E',
  'weave-coral': '#FA686F',
  'weave-light-blue': '#bae0ff',
  'weave-gray': '#AAB1C1',
  'weave-light-gray': '#00000026',
  'weave-blue': '#8CB1ED',
  'weave-lavender-blue': '#D3ADF7',
  'weave-error': '#FF4D4F',
  'weave-error-border': '#CF1322',
  primary: '#1B202B',
  'weave-primary': '#1B202B',
  'weave-primary-400': '#CCD1DD',
  'weave-primary-600': '#6D7686',
  'weave-primary-300': '#E4E7EF',
  'weave-primary-200': '#3B424F',
  'weave-primary-100': '#F7F8FC',
  'weave-secondary': '#1B202BA6',
  'weave-tertiary': '#1B202B73',
  'weave-gray-light': '#EDEFF4',
  'weave-icon-gray': '#828386',
  'weave-icon-gray-light': '#00000073',
  'weave-dark-green': '#84B840',
  'weave-new-gray': '#1B202B40',
  'weave-color-product-active': '#5D8627',
  'weave-color-product-base': '#77A63A',
  'weave-color-product-border': '#C1DD9D',
  'weave-color-primary-border': '#C1C6D1',
  'weave-gray-lighter': '#1B202B73',
  'weave-background-spotlight': '#1B202B',
  'weave-light-solid': '#fff',
  'weave-color-product-text': '#2C4110',
  'weave-color-text-disabled': '#1B202B40',
  'weave-color-button-hover': '#8FBE50',
  'weave-row-fill': '#1B202B0F',
  'weave-color-product-background': '#F8FCF3',
  'weave-warning': '#FAC114',
  'weave-warning-hover': '#FFEDB8',
  'weave-warning-light': '#FFF9E5',
  'weave-warning-dark': '#856300',
  // references related colors
  'block-file': '#5890E9',
  'block-file-hover': '#F5F7FD',
  'block-text': '#4EB7B5',
  'block-text-hover': '#F7FBFB',
  'file-viewer': '#F1F2F2',
  'highlight-mode': '#6C7486',
  'ref-page-number': '#1B202B40',
  'ref-reload-bg': '#6C74860D',
  'ref-reload-border': '#1B202B26',
  'side-nav-bg': '#F8F8F9',
};
