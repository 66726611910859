/// Taken from the rxJS library, which doesn't export the isnumeric method publicly...

export class TypeUtils {
  static isArray = Array.isArray || ((x) => x && typeof x.length === 'number');

  static isBoolean = (val: any) => typeof val === 'boolean' && !!val === val;

  static isNumeric = (val: any) => {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat
    return !TypeUtils.isArray(val) && val - parseFloat(val) + 1 >= 0;
  };

  static convertStringToBoolean = (val: string) => {
    if (!val) {
      return false;
    }
    if (val.toLowerCase() === 'true' || val.toLowerCase() === '1') {
      return true;
    } else {
      return false;
    }
  };

  static isObject = (val: any) => {
    return typeof val === 'object' && val !== null;
  };
}
