import { useContext } from 'react';

import UserContext from '~/core/contexts/UserContext';
import { TypeUtils } from '~/core/lib/typeUtils';

type InternalOnlyFeatureFlags =
  | 'internal-enable-diagnostics'
  | 'internal-allow-extracted-data-viewer';

type GeneralFeatureFlags =
  | 'allow-document-editor-typeahead'
  | 'allow-sadl' // named so as this is being used by the BE as well
  | 'allow-platform' // named so as this is being used by the BE as well
  | 'fe-allow-dvd'
  | 'fe-allow-ind-manager-regeneration-on-certain-sections'
  | 'fe-allow-new-review-pane-in-doc-editor'
  | 'fe-allow-bluray';

export type FEATURE_FLAGS = InternalOnlyFeatureFlags | GeneralFeatureFlags;

const getFeatureFlagOverride = (flagName: string) => {
  if (!import.meta.env.VITE_ALLOW_LOCAL_FEATURE_FLAG_OVERRIDES) {
    return null;
  }
  if (!import.meta.env.VITE_FEATURE_FLAG_OVERRIDE_SETTINGS) {
    return null;
  }

  let overrides;
  try {
    const rawOverrides = JSON.parse(
      import.meta.env.VITE_FEATURE_FLAG_OVERRIDE_SETTINGS,
    );
    overrides = rawOverrides.map((rawOverride: any) => {
      return {
        name: rawOverride['name'],
        value: TypeUtils.convertStringToBoolean(rawOverride['value']),
      };
    });
  } catch (e) {
    console.error(
      'Error encountered while parsing overrides!  Please check that your entry for overrides is in the appropriate name/value format.',
      e,
    );
  }

  if (!overrides) {
    return null;
  }
  const overriddenFlag = overrides.find(
    (override: any) => override.name === flagName,
  );
  if (overriddenFlag) {
    return overriddenFlag;
  }
  return null;
};

/**
 * Checks if a feature is enabled or not. If the result is undefined, probably the feature is
 * either missing from the posthog env, or loading.
 * @param feature the name of the feature flag
 * @returns a bool specifying if it's enabled or not, otherwise undefined
 */
export const useIsFeatureEnabled = (
  feature: FEATURE_FLAGS,
): boolean | undefined => {
  const { userFeatureFlags } = useContext(UserContext);

  // If the env indicates that feature flag overriding is permitted (this setting is intentionally
  // not present at all in staging or prod) and the feature flag requested is a match for one of the
  // flags defined in the .env, use the value defined in the .env instead.
  const flagOverride = getFeatureFlagOverride(feature);
  if (flagOverride) {
    return flagOverride.value;
  }
  return userFeatureFlags[feature];
};
