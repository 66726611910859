import './index.scss';

import { Suspense, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ScrollToTop } from '~/design-system/components/atoms/ScrollToTop/ScrollToTop';
import WeaveSpinner from '~/design-system/components/molecules/WeaveSpinner/WeaveSpinner';
import { useDashboardData } from '~/features/dashboard/hooks/useDashboardData';
import OrganizationMenu from '~/features/organization-management/components/organisms/OrganizationMenu/OrganizationMenu';
import { useOrganizationData } from '~/features/organization-management/hooks/useOrganizationData';
import { PrivateRoutes } from '~/router/private-routes';

interface Props {
  children: React.ReactNode;
}

export const DefaultLayout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loadingOrgStats } = useDashboardData();
  const { switchOrganizations } = useOrganizationData();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      const el = document.getElementById('default-layout');
      if (el) {
        el.style.backgroundImage = 'none';
      }
    };
  }, [location]);

  const handleSwitchOrganization = async (organizationId: string) => {
    setIsLoading(true);
    await switchOrganizations
      .mutateAsync({
        organizationId: organizationId,
      })
      .then(() => {
        navigate(PrivateRoutes.DASHBOARD);
      })
      .finally(() => {
        if (!loadingOrgStats) {
          setIsLoading(false);
        }
      });
  };

  return isLoading ?
      <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
    : <>
        <ScrollToTop />

        <div className="h-full" id="default-layout">
          <div className="w-full fixed py-[30px] h-[45px] z-10 left-0 top-0 flex items-center justify-between border-b border-solid border-b-weave-primary-400 bg-white">
            <div className="flex items-center justify-start">
              <div className="h-full flex justify-center items-center px-4 mt-[7px]">
                <Link to={PrivateRoutes.DASHBOARD}>
                  <img
                    alt="weave"
                    width="32px"
                    loading="eager"
                    decoding="sync"
                    src="/images/weave-logo-dark-blue.svg"
                  />
                </Link>
              </div>
            </div>
            <OrganizationMenu
              handleSwitchOrganization={handleSwitchOrganization}
            />
          </div>
          <div className="pt-11">
            <Suspense
              fallback={
                <WeaveSpinner
                  isFullscreen={true}
                  hasIndicator={true}
                  size="xl"
                />
              }
            >
              {props.children}
            </Suspense>
          </div>
        </div>
      </>;
};
