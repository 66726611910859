import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { Checkbox, Modal } from 'antd';
import { ReactNode } from 'react';

import { cn } from '~/core/lib/tailwind-util';

import BodyText from '../../atoms/Text/Body';
import { Heading } from '../../atoms/Text/Heading';
import { Weave2024Button } from '../../atoms/Weave2024Button';

const getIcon = (iconStatus: string) => {
  const iconStyles = {
    base: { fontSize: '24px', marginRight: '15px' },
    colors: {
      info: '#6C7486',
      error: '#F5222D',
      success: '#A0D911',
      warning: '#FAC114',
    },
  };

  type IconType = Record<string, ReactNode>;

  const icons: IconType = {
    info: (
      <InfoCircleFilled
        className="basis-1/12"
        style={{ ...iconStyles.base, color: iconStyles.colors.info }}
      />
    ),
    error: (
      <CloseCircleFilled
        className="basis-1/12"
        style={{ ...iconStyles.base, color: iconStyles.colors.error }}
      />
    ),
    success: (
      <CheckCircleFilled
        className="basis-1/12"
        style={{ ...iconStyles.base, color: iconStyles.colors.success }}
      />
    ),
    warning: (
      <ExclamationCircleFilled
        className="basis-1/12"
        style={{ ...iconStyles.base, color: iconStyles.colors.warning }}
      />
    ),
    confirm: (
      <ExclamationCircleOutlined
        className="basis-1/12"
        style={{ ...iconStyles.base, color: iconStyles.colors.warning }}
      />
    ),
  };

  return icons[iconStatus as keyof IconType];
};

export interface WeaveModalProps {
  cancelButtonText: string;
  children: ReactNode;
  description?: string;
  hasNavigation?: boolean;
  hideCancelButton?: boolean;
  hideOkButton?: boolean;
  iconStatus?: 'info' | 'error' | 'success' | 'warning' | 'confirm';
  okButtonDisabled?: boolean;
  okButtonText: string;
  okIcon?: ReactNode;
  onCancel: () => void;
  onChange?: () => void;
  onNavigationPrev?: () => void;
  onNavigationNext?: () => void;
  onOk: () => void;
  onTertiary?: () => void;
  open: boolean;
  section?: string;
  showCloseButton?: boolean;
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full-screen';
  tertiaryActionElement?: 'checkbox' | 'button';
  tertiaryActionText?: string;
  title: string | ReactNode;
  trackingId: string;
  variant?: 'default' | 'review' | 'danger';
  modalContainerClassName?: string;
  modalContentClassName?: string;
  dataTestId?: string;
}

const getWidthFromSize = (size: string) => {
  const sizes: Record<string, number | string> = {
    sm: 400,
    md: 520,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
    'full-screen': '100vw',
  };

  return sizes[size];
};

export const WeaveModal = ({
  cancelButtonText,
  children,
  description,
  hasNavigation = false,
  hideCancelButton = false,
  hideOkButton = false,
  iconStatus = 'confirm',
  okButtonDisabled = false,
  okButtonText,
  okIcon,
  onCancel,
  onChange,
  onNavigationPrev,
  onNavigationNext,
  onOk,
  onTertiary,
  open,
  section,
  showCloseButton = true,
  size,
  tertiaryActionElement,
  tertiaryActionText,
  title,
  trackingId,
  variant = 'default',
  modalContainerClassName,
  modalContentClassName,
  dataTestId = 'weave-modal',
}: WeaveModalProps) => {
  const isSmall = size === 'sm';
  const _showCloseButton = !isSmall && showCloseButton;
  const widthDerivedFromSize = getWidthFromSize(size);
  const iconDerivedFromStatus = getIcon(iconStatus);
  const isLargeScreenSize = ['lg', 'xl', '2xl', 'full-screen'].includes(size);
  const textColor = variant === 'review' ? 'text-purple-5' : 'text-weave-green';

  // @j-weave: Unlike when the modal is a more specific size, "full-screen" is a special indicator that we want the modal to take up the entirety
  // of the viewport.  As such, there are several base styles that change in order to accomodate this.

  const modalClassName = cn(
    size === 'full-screen' ?
      'h-[100vh] fixed top-0 left-0 right-0 bottom-0 w-[100vw]'
    : 'max-h-[90vh]',
    'overflow-x-hidden overflow-y-hidden',
    modalContainerClassName,
  );

  return (
    <Modal
      centered={size !== 'full-screen'}
      closable={_showCloseButton}
      className={modalClassName}
      footer={null}
      open={open}
      onCancel={onCancel}
      styles={{
        body:
          size === 'full-screen' ?
            {
              padding: isSmall ? '10px' : '10px 30px',
              height: '100vh',
              top: 0,
            }
          : {
              padding: isSmall ? '10px' : '10px 30px',
            },
        content:
          size === 'full-screen' ?
            {
              height: '100vh',
              left: 0,
              right: 0,
              top: 0,
            }
          : undefined,
      }}
      width={widthDerivedFromSize}
      destroyOnClose={true}
      data-tracking-id={trackingId}
      data-testid={dataTestId}
    >
      {!isSmall && hasNavigation && (
        <div
          className={
            size === 'full-screen' ?
              'flex justify-end relative top-[-6px] right-[12px] gap-2'
            : 'flex justify-end relative right-[12px] gap-2 bottom-[-6px] left-[2px]'
          }
        >
          <button type="button" className="unstyled-button">
            <ArrowLeftOutlined
              onClick={onNavigationPrev}
              style={{ fontSize: '16px', color: '#AAB1C1' }}
            />
          </button>
          <button type="button" className="unstyled-button">
            <ArrowRightOutlined
              onClick={onNavigationNext}
              style={{ fontSize: '16px', color: '#AAB1C1' }}
            />
          </button>
        </div>
      )}
      {section && !isSmall && (
        <Heading
          size="h2"
          className={cn(
            'my-2 text-xs font-semibold leading-tight uppercase font-matter tracking-2px weave-modal-section-heading',
            textColor,
          )}
          data-testid="weave-modal-section-heading"
        >
          {section}
        </Heading>
      )}
      <div className="flex flex-row flex-wrap items-start max-w-full overflow-x-hidden">
        {isSmall && iconDerivedFromStatus}
        <div
          className={cn(
            'flex flex-col max-w-full grow',
            isSmall ? 'basis-10/12' : 'basis-12/12',
          )}
        >
          <Heading
            weight="normal"
            size="h3"
            className={cn(
              'font-matter text-weave-primary-200 leading-tight weave-modal-title',
              !isSmall ? 'text-xl' : 'text-lg',
            )}
            data-testid="weave-modal-title"
          >
            {title}
          </Heading>
          {description && !isSmall && (
            <BodyText
              className="text-weave-secondary !my-2 weave-modal-description"
              data-testid="weave-modal-description"
              size="body3"
            >
              {description}
            </BodyText>
          )}
          <div className="mask-modal-children">
            <div
              className={cn(
                size === 'full-screen' ? 'h-[76.5vh]' : 'max-h-[60vh]',
                'overflow-y-auto py-4 !pr-[10px] mr-[-10px]',
                modalContentClassName,
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex w-full items-center gap-2 mt-6',
          isLargeScreenSize && tertiaryActionElement ? 'justify-between' : (
            'justify-end'
          ),
        )}
      >
        {isLargeScreenSize && tertiaryActionElement === 'button' && (
          <Weave2024Button
            bypassCapitalization
            onClick={onTertiary}
            ghost={false}
            variant="primary"
            trackingId={`${trackingId}_TERTIARY_BUTTON`}
            data-testid="weave-modal-tertiary-button"
          >
            {tertiaryActionText}
          </Weave2024Button>
        )}
        {isLargeScreenSize && tertiaryActionElement === 'checkbox' && (
          // @j-weave: will add Checkbox to instrumentation later
          <Checkbox onChange={onChange}>{tertiaryActionText}</Checkbox>
        )}
        <div className="flex gap-2">
          {!hideCancelButton && (
            <Weave2024Button
              bypassCapitalization
              onClick={onCancel}
              ghost={false}
              variant="primary"
              trackingId={`${trackingId}_CANCEL`}
              data-testid="weave-modal-cancel-button"
            >
              {cancelButtonText}
            </Weave2024Button>
          )}
          {!hideOkButton && (
            <Weave2024Button
              bypassCapitalization
              variant={variant ? variant : 'default'}
              onClick={onOk}
              disabled={okButtonDisabled}
              icon={okIcon}
              trackingId={`${trackingId}_OK`}
              data-testid="weave-modal-ok-button"
            >
              {okButtonText}
            </Weave2024Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WeaveModal;
