import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import {
  DocumentContentTree,
  DocumentGridCellBlock,
  TopLevelDocumentBlock,
} from '~/core/domain/blocks';
import { AssetDocument } from '~/core/domain/types';
import { TreeUtils } from '~/core/lib/treeUtils';
import logger from '~/core/providers/logger';
import { DocumentEditorServiceMapper } from '~/features/document-editor/mappers/responses/mappers';

import {
  Asset,
  CreateAssetPayload,
  CreateTemplateSetPayload,
  DocumentLockPayload,
  EditorTemplateSavePayload,
  GenerateDocumentContentRequestPayload,
  InstructionBlockPayload,
  ProcessCheckResponse,
  Products,
  TemplateDetail,
  TemplateSet,
  UpdateAssetPayload,
  UpdateTemplateByIdPayload,
  UpdateTemplateSetByIdPayload,
  WorkflowInitiationResponse,
} from './types';

const platformEndpoints = {
  assets: `platform/assets`, // detail for organization assets, used for GET and POST
  assetById: (assetId: string) => `platform/asset/${assetId}`, // detail for single asset, used for GET, PUT, and DELETE
  updateProductsById: (assetId: string) => `platform/asset/${assetId}/products`, // updates the list of products associated with an asset
  templateSets: `platform/template-sets`, // detail for templateSets, used for GET and POST
  templateSetById: (templateSetId: string) =>
    `platform/template-set/${templateSetId}`, // detail for single templateSet, includes document level tmeplate info used for GET, PUT, DELETE
  templateById: (templateId: string) => `platform/template/${templateId}`, // detail for single template used for GET and PUT
  generatePlatformContent: (
    assetId: string,
    documentId: string, // instruction block level generation for platform model
  ) => `rag/asset/${assetId}/document/${documentId}/generate-content`,
  documentContent: (assetId: string, assetDocumentId: string) =>
    `platform/asset/${assetId}/document/${assetDocumentId}`,
  documentPlayground: (assetId: string, assetDocumentId: string) =>
    `rag/asset/${assetId}/document/${assetDocumentId}/generate-playground`,
  processCheckStatus: (
    assetId: string,
    assetDocumentId: string,
    processInstanceId: string,
  ) =>
    `rag/asset/${assetId}/document/${assetDocumentId}/generate-playground/${processInstanceId}`,
  editorTemplateSave: (assetId: string, assetDocumentId: string) =>
    `platform/asset/${assetId}/document/${assetDocumentId}/template-content`,
  documentLock: (assetId: string, assetDocumentId: string) =>
    `platform/asset/${assetId}/document/${assetDocumentId}/lock`,
  insertFigureInDocument: (assetId: string, assetDocumentId: string) =>
    `platform/asset/${assetId}/document/${assetDocumentId}/image`,
};

const indApiAdapter = new IndHttpAdapter();

const getListOfAssets: () => Promise<Asset[]> = async () => {
  const response = await indApiAdapter.get<Asset[]>({
    endpoint: platformEndpoints.assets,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as Asset[];
};

const createAsset: (
  createAssetPayload: CreateAssetPayload,
) => Promise<any> = async (createAssetPayload) => {
  const response = await indApiAdapter.post<CreateAssetPayload>({
    endpoint: platformEndpoints.assets,
    data: createAssetPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const getAssetById: ({
  assetId,
}: {
  assetId: string;
}) => Promise<Asset> = async ({ assetId }) => {
  const response = await indApiAdapter.get<Asset>({
    endpoint: platformEndpoints.assetById(assetId),
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as Asset;
};

const updateAssetById: ({
  assetId,
  updateAssetPayload,
}: {
  assetId: string;
  updateAssetPayload: UpdateAssetPayload;
}) => Promise<any> = async ({ assetId, updateAssetPayload }) => {
  const response = await indApiAdapter.put<UpdateAssetPayload>({
    endpoint: platformEndpoints.assetById(assetId),
    data: updateAssetPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const deleteAssetById: ({
  assetId,
}: {
  assetId: string;
}) => Promise<any> = async ({ assetId }) => {
  const response = await indApiAdapter.delete<any>({
    endpoint: platformEndpoints.assetById(assetId),
    data: null,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const updateListOfProductsByAssetId: ({
  assetId,
  productKeys,
}: {
  assetId: string;
  productKeys: Products[];
}) => Promise<any> = async ({ assetId, productKeys }) => {
  const response = await indApiAdapter.put<Products[]>({
    endpoint: platformEndpoints.updateProductsById(assetId),
    data: productKeys,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const getTemplateSets: () => Promise<TemplateSet[]> = async () => {
  const response = await indApiAdapter.get<TemplateSet[]>({
    endpoint: platformEndpoints.templateSets,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as TemplateSet[];
};

const createTemplateSet: (
  createTemplateSetPayload: CreateTemplateSetPayload,
) => Promise<any> = async (createTemplateSetPayload) => {
  const response = await indApiAdapter.post<CreateTemplateSetPayload>({
    endpoint: platformEndpoints.templateSets,
    data: createTemplateSetPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const getTemplateSetByTemplateSetId: ({
  templateSetId,
}: {
  templateSetId: string;
}) => Promise<TemplateSet> = async ({ templateSetId }) => {
  const response = await indApiAdapter.get<TemplateSet>({
    endpoint: platformEndpoints.templateSetById(templateSetId),
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as TemplateSet;
};

const updateTemplateSetByTemplateSetId: ({
  templateSetId,
  updateTemplateSetByIdPayload,
}: {
  templateSetId: string;
  updateTemplateSetByIdPayload: UpdateTemplateSetByIdPayload;
}) => Promise<any> = async ({
  templateSetId,
  updateTemplateSetByIdPayload,
}) => {
  const response = await indApiAdapter.put<UpdateTemplateSetByIdPayload>({
    endpoint: platformEndpoints.templateSetById(templateSetId),
    data: updateTemplateSetByIdPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const deleteTemplateSetByTemplateSetId: ({
  templateSetId,
}: {
  templateSetId: string;
}) => Promise<any> = async ({ templateSetId }) => {
  const response = await indApiAdapter.delete<any>({
    endpoint: platformEndpoints.templateSetById(templateSetId),
    data: null,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const getTemplateById: ({
  templateId,
}: {
  templateId: string;
}) => Promise<TemplateDetail> = async ({ templateId }) => {
  const response = await indApiAdapter.get<TemplateDetail>({
    endpoint: platformEndpoints.templateById(templateId),
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as TemplateDetail;
};

const updateTemplateById: ({
  templateId,
  updateTemplateByIdPayload,
}: {
  templateId: string;
  updateTemplateByIdPayload: UpdateTemplateByIdPayload;
}) => Promise<any> = async ({ templateId, updateTemplateByIdPayload }) => {
  const response = await indApiAdapter.put<UpdateTemplateByIdPayload>({
    endpoint: platformEndpoints.templateById(templateId),
    data: updateTemplateByIdPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const getDocumentContent: ({
  assetId,
  assetDocumentId,
}: {
  assetId: string;
  assetDocumentId: string;
}) => Promise<AssetDocument> = async ({ assetId, assetDocumentId }) => {
  const response = await indApiAdapter.get<any>({
    endpoint: platformEndpoints.documentContent(assetId, assetDocumentId),
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  const mappedResponse =
    DocumentEditorServiceMapper.map.backendAssetDocumentResponse.to.frontEndAssetDocumentFormat(
      response.data,
    );

  console.log('Mapped document response', mappedResponse);

  return mappedResponse;
};

const insertFigureInDocument = async ({
  assetId,
  documentId,
  imageFile,
  underneathBlock,
}: {
  assetId: string;
  documentId: string;
  imageFile: any;
  underneathBlock: TopLevelDocumentBlock;
}): Promise<string> => {
  const imageProcessingResponse = await indApiAdapter.post<any>({
    endpoint: platformEndpoints.insertFigureInDocument(assetId, documentId),
    data: {
      position:
        underneathBlock.block_type === 'section' ?
          0
        : underneathBlock.position + 1,
      parent_block_id:
        underneathBlock.block_type === 'section' ?
          underneathBlock.id
        : underneathBlock.parent,
      image: imageFile,
    },
    headerOverride: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment; filename="${imageFile.name}"`,
    },
  });

  if (imageProcessingResponse.error) {
    throw new Error(imageProcessingResponse.error.message);
  }
  return imageProcessingResponse.data.process_instance_id;
};

const saveBlock = async ({
  assetId,
  documentId,
  updatedBlock,
}: {
  assetId: string;
  documentId: string;
  updatedBlock: TopLevelDocumentBlock | DocumentGridCellBlock;
}) => {
  const rawContentResponse = await indApiAdapter.get<any>({
    endpoint: platformEndpoints.documentContent(assetId, documentId),
  });

  const rawContentArrayFromServer = rawContentResponse.data.content;

  // Find the block in the flattened list, and replace it
  const updatedBlockIndex = rawContentArrayFromServer.findIndex(
    (block: TopLevelDocumentBlock) => block.id === updatedBlock.id,
  );

  if (updatedBlockIndex === -1) {
    logger.logError('No block index found to update', {
      assetId: assetId,
      documentId: documentId,
      updatedBlock: updatedBlock,
    });
    return;
  }
  rawContentArrayFromServer[updatedBlockIndex] = updatedBlock;

  const arrayWithoutTheRootNode = rawContentArrayFromServer.slice(1);

  const response = await indApiAdapter.put<any>({
    endpoint: platformEndpoints.documentContent(assetId, documentId),
    data: {
      blocks: arrayWithoutTheRootNode,
    },
  });

  return response;
};

const saveDocumentContentTree = async (
  assetId: string,
  documentId: string,
  documentContentTree: DocumentContentTree,
) => {
  const flattenedContentTree = TreeUtils.flattenTree({
    sourceTree: documentContentTree[0],
  });
  const flattenedContentTreeWithoutRootNode = flattenedContentTree.slice(1);
  console.log(
    'Flattened content without root',
    flattenedContentTreeWithoutRootNode,
  );
  const response = await indApiAdapter.put<any>({
    endpoint: platformEndpoints.documentContent(assetId, documentId),
    data: {
      blocks: flattenedContentTreeWithoutRootNode,
    },
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response;
};

const generateDocumentContent: ({
  assetId,
  assetDocumentId,
  generateDocumentContentPayload,
}: {
  assetId: string;
  assetDocumentId: string;
  generateDocumentContentPayload: GenerateDocumentContentRequestPayload;
}) => Promise<WorkflowInitiationResponse | undefined> = async ({
  assetId,
  assetDocumentId,
  generateDocumentContentPayload,
}) => {
  const response = await indApiAdapter.put<WorkflowInitiationResponse>({
    endpoint: platformEndpoints.generatePlatformContent(
      assetId,
      assetDocumentId,
    ),
    data: generateDocumentContentPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const generateDocumentPlaygroundContent: ({
  assetId,
  assetDocumentId,
  instructionBlockPayload,
}: {
  assetId: string;
  assetDocumentId: string;
  instructionBlockPayload: InstructionBlockPayload;
}) => Promise<any> = async ({
  assetId,
  assetDocumentId,
  instructionBlockPayload,
}) => {
  const response = await indApiAdapter.post<InstructionBlockPayload>({
    endpoint: platformEndpoints.documentPlayground(assetId, assetDocumentId),
    data: instructionBlockPayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const processCheckStatus: ({
  assetId,
  assetDocumentId,
  processInstanceId,
}: {
  assetId: string;
  assetDocumentId: string;
  processInstanceId: string;
}) => Promise<ProcessCheckResponse> = async ({
  assetId,
  assetDocumentId,
  processInstanceId,
}) => {
  const response = await indApiAdapter.get<ProcessCheckResponse>({
    endpoint: platformEndpoints.processCheckStatus(
      assetId,
      assetDocumentId,
      processInstanceId,
    ),
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as ProcessCheckResponse;
};

const editorTemplateSave: ({
  assetId,
  assetDocumentId,
  editorTemplateSavePayload,
}: {
  assetId: string;
  assetDocumentId: string;
  editorTemplateSavePayload: EditorTemplateSavePayload;
}) => Promise<any> = async ({
  assetId,
  assetDocumentId,
  editorTemplateSavePayload,
}) => {
  const response = await indApiAdapter.put<EditorTemplateSavePayload>({
    endpoint: platformEndpoints.editorTemplateSave(assetId, assetDocumentId),
    data: editorTemplateSavePayload,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

const documentLock: ({
  assetId,
  assetDocumentId,
  canUpdateDocument,
  documentLockPayload,
}: {
  assetId: string;
  assetDocumentId: string;
  canUpdateDocument: boolean;
  documentLockPayload: DocumentLockPayload;
}) => Promise<any> = async ({
  assetId,
  assetDocumentId,
  canUpdateDocument,
  documentLockPayload,
}) => {
  if (canUpdateDocument) {
    const response = await indApiAdapter.put({
      endpoint: platformEndpoints.documentLock(assetId, assetDocumentId),
      data: documentLockPayload,
    });

    if (response.error) {
      logger.logError(response.error.message, {
        ...response.error,
      });
      throw Error(response.error.message);
    }

    if (response.error) {
      logger.logError('Unforeseen error when attempting to get/set lock', {
        assetId: assetId,
        assetDocumentId: assetDocumentId,
        error: response.error,
      });
      return undefined; // In the event of an unforeseen issue, we don't want to block a user from being able to edit.
    }

    return response.data;
  } else {
    const response = await indApiAdapter.get({
      endpoint: platformEndpoints.documentLock(assetId, assetDocumentId),
    });

    if (response.error) {
      logger.logError(response.error.message, {
        ...response.error,
      });
      throw Error(response.error.message);
    }

    return response.data;
  }
};

export {
  createAsset,
  createTemplateSet,
  deleteAssetById,
  deleteTemplateSetByTemplateSetId,
  documentLock,
  editorTemplateSave,
  generateDocumentContent,
  generateDocumentPlaygroundContent,
  getAssetById,
  getDocumentContent,
  getListOfAssets,
  getTemplateById,
  getTemplateSetByTemplateSetId,
  getTemplateSets,
  insertFigureInDocument,
  processCheckStatus,
  saveBlock,
  saveDocumentContentTree,
  updateAssetById,
  updateListOfProductsByAssetId,
  updateTemplateById,
  updateTemplateSetByTemplateSetId,
};
