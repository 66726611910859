// Disabling - this is one of three areas where we directly reference Sentry: two abstractions and one initialization
// a) the Sentry.init
// b) SentryLogProvider
// c) --> [this one] SentryUserReportingProvider
// eslint-disable-next-line local/no-sentry-import
import * as Sentry from '@sentry/react';

import { IndProfile } from '~/core/domain/types';

import { UserReportingProvider } from '..';

export class SentryUserReportingProvider implements UserReportingProvider {
  identify: ({ uuid, email }: IndProfile) => void = ({ uuid, email }) => {
    Sentry.setUser({ id: email, email: email, uuid: uuid });
  };

  resetIdentification: () => void = () => {
    Sentry.setUser(null);
  };
}
