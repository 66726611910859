import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import UserContext from '~/core/contexts/UserContext';
import { QueryKeys } from '~/core/hooks/const';
import { useToast } from '~/core/hooks/core/useToast';
import { getFeatureFlags } from '~/core/services/UserService';
import { QueryKeys as QueryKeysTemplates } from '~/features/templates-space/hooks/useTemplates';

import * as InvitationService from '../../organization-management/services/InvitationService';
import * as OrganizationService from '../../organization-management/services/OrganizationService';
import { OrgRoleData, OrgRoleId, UserRoleData } from '../domain/types';

export const useOrganizationData = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { userProfile: currentUserProfile } = useContext(UserContext);

  const { isPending: loadingOrganization, data: organization } = useQuery(
    {
      queryKey: QueryKeys.GET_ORGANIZATION,
      queryFn: async () => OrganizationService.getOrganization(),
    },
    queryClient,
  );

  // get all org roles
  const { data: organizationRoles, refetch: refetchOrganizationRoles } =
    useQuery(
      {
        queryKey: QueryKeys.GET_ORGANIZATION_ROLES,
        queryFn: async () => await OrganizationService.getOrganizationRoles(),
      },
      queryClient,
    );

  // add a single org role
  const addOrganizationRole = useMutation<OrgRoleData, Error, any>({
    mutationFn: async (data) =>
      await OrganizationService.addOrganizationRole(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.ORGANIZATION_ROLE,
      });
      toast.success('Role created successfully.');
    },
    onError: () => toast.error('Role was not created successfully.'),
  });

  // update a single org role
  const updateOrganizationRole = useMutation<
    OrgRoleData & OrgRoleId,
    Error,
    any
  >({
    mutationFn: async (data) =>
      await OrganizationService.updateOrganizationRole(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.ORGANIZATION_ROLE,
      });
      toast.success('Role updated successfully.');
    },
    onError: () => toast.error('Role was not updated successfully.'),
  });

  // delete a single org role
  const deleteOrganizationRole = useMutation<OrgRoleId, Error, any>({
    mutationFn: async (data) =>
      await OrganizationService.deleteOrganizationRole(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.ORGANIZATION_ROLE,
      });
      toast.success('Role deleted successfully.');
    },
  });

  // gets a users permissions
  const { data: userPermissions, refetch: refetchUserPermissions } = useQuery(
    {
      queryKey: QueryKeys.GET_USER_PERMISSIONS,
      queryFn: async () => OrganizationService.getUserPermissions(),
    },
    queryClient,
  );

  // updates a users roles - role assignment
  const updateUserRoles = useMutation<UserRoleData, Error, any>({
    mutationFn: async (data) => await OrganizationService.updateUserRoles(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.USER_ROLES,
      });
      toast.success('Role assigned successfully.');
    },
    onError: () => toast.error('Role was not assigned successfully.'),
  });

  // get dossier tree
  const { data: dossierTree, isLoading: dossierIsLoading } = useQuery(
    {
      queryKey: QueryKeys.GET_DOSSIER_TREE,
      queryFn: async () => await OrganizationService.getDossierTree(),
    },
    queryClient,
  );

  const {
    isPending: loadingMembers,
    data: members,
    refetch: refetchOrganizationMembers,
  } = useQuery(
    {
      queryKey: QueryKeys.GET_ORG_MEMBERS,
      queryFn: async () => OrganizationService.getMembers(),
    },
    queryClient,
  );

  const removeUser = useMutation({
    mutationFn: (mutationArguments: { userId: string }) =>
      OrganizationService.removeUser(mutationArguments),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_MEMBERS,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORGANIZATION,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_STATS,
      });
      toast.success('User removed successfully.');
    },
    onError: () => toast.error('User not removed successfully.'),
  });

  const invalidateInvitation = useMutation({
    mutationFn: (mutationArguments: { invitationId: string }) =>
      InvitationService.invalidateInvitation(mutationArguments),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_MEMBERS,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORGANIZATION,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_STATS,
      });
      toast.success('User invite rescinded successfully');
    },
    onError: () => toast.error('User invite not rescinded successfully'),
  });

  const inviteUser = useMutation({
    mutationFn: async (mutationArguments: {
      emailAddresses: string[];
      organizationId: string;
      orgRoleKey: string;
    }) => InvitationService.sendInvitations(mutationArguments),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_MEMBERS,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORGANIZATION,
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_STATS,
      });
      toast.success(
        'Invitations sent successfully! Users will be listed as pending until they accept the invitation.',
      );
    },
    onError: () => toast.error('Failed to send one or more invitations.'),
  });

  const resendInvitation = useMutation({
    mutationFn: (mutationArguments: { invitationId: string }) =>
      InvitationService.resendInvitation(mutationArguments),
    // Unlike the other mutations, the resending of an invite does not require resetting of any other queries.
  });

  const switchOrganizations = useMutation<any, Error, any>({
    mutationFn: async (data: { organizationId: string }) =>
      await OrganizationService.switchOrganizations(data),
    onSuccess: async (data: { organizationId: string }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_PROFILE,
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_ORGANIZATION,
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_ORG_STATS,
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_ORG_MEMBERS,
        }),
        queryClient.invalidateQueries({
          queryKey: ['platform-assets'],
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_IND_APPLICATIONS(),
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeysTemplates.GET_GENERATION_TEMPLATES({
            indId: '',
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeysTemplates.GET_ORG_CONTENT_TEMPLATES,
        }),
        await getFeatureFlags({
          userId: currentUserProfile.uuid,
          organizationId: data.organizationId,
          forceRefreshOfCache: true,
        }),
      ]).then(() => {
        toast.success('Switched organizations successfully.');
      });
    },
    onError: () => toast.error('Could not switch organizations successfully.'),
  });

  return {
    refetchOrganizationRoles,
    refetchOrganizationMembers,
    refetchUserPermissions,
    userPermissions,
    dossierTree,
    dossierIsLoading,
    currentUserProfile,
    loadingOrganization,
    organization,
    organizationRoles,
    deleteOrganizationRole,
    updateOrganizationRole,
    addOrganizationRole,
    loadingMembers,
    members,
    removeUser,
    invalidateInvitation,
    inviteUser,
    resendInvitation,
    updateUserRoles,
    switchOrganizations,
  };
};
