import '@mdxeditor/editor/style.css';
import '@weavebio/ui-toolkit/dist/style.css';
import 'react-multi-email/dist/style.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './core/providers/LogRenders';
import './index.scss';
import './tailwind.css';

import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '~/core/contexts/AuthContext';
import { GlobalErrorBoundary } from '~/core/providers/GlobalErrorBoundary';
import { MswProvider } from '~/core/providers/MswProvider';
import { ReactQueryClient } from '~/core/providers/ReactQueryClient';
import { IndRouter } from '~/router';

// This is our new theme provider
import WeaveIndThemeProvider from './core/providers/WeaveIndTheme';
// Can't remove legacy theme yet --things like datepicker still use it
import { WeaveTheme } from './core/providers/WeaveTheme';

function App() {
  return (
    <MswProvider>
      <WeaveIndThemeProvider>
        <WeaveTheme>
          <ReactQueryClient>
            <BrowserRouter>
              <GlobalErrorBoundary>
                <AuthProvider>
                  <IndRouter />
                </AuthProvider>
              </GlobalErrorBoundary>
            </BrowserRouter>
          </ReactQueryClient>
        </WeaveTheme>
      </WeaveIndThemeProvider>
    </MswProvider>
  );
}

export default App;
