import { LexicalEditor } from 'lexical';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { VersionControlRecord } from '~/features/document-editor/domain/types';

import { IBlockPointer } from '../domain/types';

interface GlobalStore {
  myDocsTotalCount: number;
  setMyDocsTotalCount: (totalCount: number) => void;
  generatingSections: IndSectionOutline[];
  setGeneratingSections: (sections: IndSectionOutline[]) => void;
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  subHeaderHeight: number;
  setSubHeaderHeight: (height: number) => void;
  selectedBlocks: ISelectedBlockType[];
  setSelectedBlocks: (
    blocks: ISelectedBlockType[],
    updateDesign?: boolean,
  ) => void;
  addSelectedBlock: (block: ISelectedBlockType) => void;
  refinementInProgress?: {
    sectionNumber: string;
    blockId: string;
  };
  setRefinementInProgress: (sectionNumber: string, blockId: string) => void;
  clearRefinementInProgress: () => void;
  isFileViewerPanelOpen: boolean;
  fileViewerArgs: {
    blockPointer: IBlockPointer;
    defaultPageNumber?: number;
  } | null;
  setFileViewerPanelArgs: (
    isOpen: boolean,
    blockItem?: {
      blockPointer: IBlockPointer;
      defaultPageNumber?: number;
    } | null,
  ) => void;
  editor: LexicalEditor | null;
  setEditor: (editor: LexicalEditor | null) => void;

  // version control
  previewVersionRecord: VersionControlRecord | null;
  setPreviewVersionRecord: (versionRecord: VersionControlRecord | null) => void;

  // auto-update
  isSectionPendingAutoUpdate: boolean;
  setIsSectionPendingAutoUpdate: (isPending: boolean) => void;
}

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    (set) => ({
      myDocsTotalCount: 0,
      setMyDocsTotalCount: (totalCount) =>
        set({ myDocsTotalCount: totalCount }),
      generatingSections: [],
      setGeneratingSections: (sections) =>
        set({ generatingSections: sections }),
      headerHeight: 0,
      setHeaderHeight: (height) => set({ headerHeight: height }),
      subHeaderHeight: 0,
      setSubHeaderHeight: (height) => set({ subHeaderHeight: height }),
      selectedBlocks: [],
      setSelectedBlocks: (blocks) => {
        return set({ selectedBlocks: blocks });
      },
      refinementInProgress: undefined,
      setRefinementInProgress: (sectionNumber, blockId) => {
        return set({ refinementInProgress: { sectionNumber, blockId } });
      },
      clearRefinementInProgress: () => {
        return set({ refinementInProgress: undefined });
      },
      addSelectedBlock: (block) =>
        set((state) => ({ selectedBlocks: [...state.selectedBlocks, block] })),
      isFileViewerPanelOpen: false,
      fileViewerArgs: null,
      setFileViewerPanelArgs: (isOpen, fileViewerArgs) =>
        set({
          isFileViewerPanelOpen: isOpen,
          fileViewerArgs: fileViewerArgs,
        }),
      editor: null,
      setEditor: (editor) => set({ editor }),
      previewVersionRecord: null,
      setPreviewVersionRecord: (record) =>
        set({ previewVersionRecord: record }),
      isSectionPendingAutoUpdate: false,
      setIsSectionPendingAutoUpdate: (isPending) =>
        set({ isSectionPendingAutoUpdate: isPending }),
    }),
    {
      name: 'global-store',
    },
  ),
);

export const useEditor = () => useGlobalStore((state) => state.editor);
export const useSetEditor = () => useGlobalStore((state) => state.setEditor);

export const useMyDocsTotalCount = () =>
  useGlobalStore((state) => state.myDocsTotalCount);
export const useGeneratingSections = () =>
  useGlobalStore((state) => state.generatingSections);
export const useHeaderHeight = () =>
  useGlobalStore((state) => state.headerHeight);
export const useSubHeaderHeight = () =>
  useGlobalStore((state) => state.subHeaderHeight);
export const useSelectedBlocks = () =>
  useGlobalStore((state) => state.selectedBlocks);
export const useHasAnyBlockSelected = () =>
  useGlobalStore((state) => !!state.selectedBlocks.length);

export const useFileViewerPanel = () =>
  useGlobalStore((state) => ({
    isFileViewerPanelOpen: state.isFileViewerPanelOpen,
    fileViewerArgs: state.fileViewerArgs,
  }));
export const useHeaderHeightAndSubHeaderHeight = () =>
  useGlobalStore((state) => ({
    headerHeight: state.headerHeight,
    subHeaderHeight: state.subHeaderHeight,
  }));
export const usePreviewVersionRecord = () =>
  useGlobalStore((state) => state.previewVersionRecord);
export const useIsSectionPendingAutoUpdate = () =>
  useGlobalStore((state) => state.isSectionPendingAutoUpdate);

export const useGlobalStoreActions = () =>
  useGlobalStore((state) => ({
    setMyDocsTotalCount: state.setMyDocsTotalCount,
    setGeneratingSections: state.setGeneratingSections,
    setHeaderHeight: state.setHeaderHeight,
    setSubHeaderHeight: state.setSubHeaderHeight,
    setSelectedBlocks: state.setSelectedBlocks,
    addSelectedBlock: state.addSelectedBlock,
    setFileViewerPanelArgs: state.setFileViewerPanelArgs,
    setEditor: state.setEditor,
    setPreviewVersionRecord: state.setPreviewVersionRecord,
    setIsSectionPendingAutoUpdate: state.setIsSectionPendingAutoUpdate,
  }));
