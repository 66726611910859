import { Flex } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';

import UserContext from '~/core/contexts/UserContext';
import { IndProfile } from '~/core/domain/types';

import OrganizationMenuActions from './OrganizationMenuActions';
import OrganizationMenuButton from './OrganizationMenuButton';
import OrganizationMenuClientItem from './OrganizationMenuClientItem';
import OrganizationMenuHomeItem from './OrganizationMenuHomeItem';
import OrganizationMenuNotification from './OrganizationMenuNotification';

export interface OrganizationMenuProps {
  handleSwitchOrganization: (organizationId: string) => void;
}

export const OrganizationMenu = ({
  handleSwitchOrganization,
}: OrganizationMenuProps) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const { userProfile } = useContext(UserContext);

  useClickAway(menuRef, () => {
    setIsOrganizationMenuOpen(false);
  });

  const [isOrganizationMenuOpen, setIsOrganizationMenuOpen] =
    useState<boolean>(false);
  const [userProfileData, setUserProfileData] = useState<IndProfile | null>(
    null,
  );

  useEffect(() => {
    if (userProfile) setUserProfileData(userProfile);
  }, [userProfile]);

  const homeOrganizationName = userProfileData?.organization?.name ?? '';
  const homeOrganizationId = userProfileData?.organization?.uuid ?? '';
  const homeOrganizationLogo = userProfileData?.organization?.logo ?? null;
  const userOrganizations = userProfileData?.organizations ?? [];

  const findSelectedOrganization =
    userOrganizations.find((org) => org.is_selected) ?? null;

  const currentOrganization = findSelectedOrganization?.organization ?? null;

  return (
    <Flex className="mr-4" ref={menuRef}>
      <OrganizationMenuButton
        currentOrganization={currentOrganization}
        isOrganizationMenuOpen={isOrganizationMenuOpen}
        setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
      />

      <OrganizationMenuNotification
        organizations={userOrganizations}
        isOrganizationMenuOpen={isOrganizationMenuOpen}
      />

      {isOrganizationMenuOpen && (
        <Flex className="max-w-[300px] flex-col p-4 rounded shadow-lg fixed z-50 right-4 mt-12 border border-solid border-[#1B202B]/[.15] bg-white">
          <OrganizationMenuActions
            navigate={navigate}
            userProfile={userProfileData}
            setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
          />
          <Flex className="flex-col">
            {userOrganizations.length > 1 && (
              <>
                <OrganizationMenuHomeItem
                  currentOrganization={currentOrganization}
                  findSelectedOrganization={findSelectedOrganization}
                  handleSwitchOrganization={handleSwitchOrganization}
                  homeOrganizationId={homeOrganizationId}
                  homeOrganizationLogo={homeOrganizationLogo}
                  homeOrganizationName={homeOrganizationName}
                  setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
                />
                <OrganizationMenuClientItem
                  handleSwitchOrganization={handleSwitchOrganization}
                  homeOrganizationName={homeOrganizationName}
                  organizations={userOrganizations}
                  setIsOrganizationMenuOpen={setIsOrganizationMenuOpen}
                />
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default OrganizationMenu;
